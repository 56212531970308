import { h } from 'preact';

import faArrowLeft from '@fortawesome/fontawesome-pro/svgs/regular/arrow-left.svg';

import FaIcon from '../../common/faIcon';

import style from './style';

const backHandler = () => window.history.back();

const BackButton = () => (
  <button
    onClick={backHandler}
    type="button"
    className={style.button}
  >
    <FaIcon icon={faArrowLeft} size="lg" />
  </button>
);

export default BackButton;
