import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import classNames from 'classnames';
import style from './style';

const BurgerButton = (props) => {
  const {
    onClick,
    isActive,
    inverted,
  } = props;
  const btnRef = useRef();

  // we need to set classes after render to keep the animation working
  useEffect(() => {
    if (isActive && btnRef && btnRef.current) {
      requestAnimationFrame(() => {
        btnRef.current.setAttribute('class', style.activeBurgerButton);
      });
    }
  }, [isActive]);

  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(style.burgerButton, { [style.inverted]: inverted })}
      aria-label="menu"
      aria-expanded={isActive ? 'true' : 'false'}
      ref={btnRef}
    >
      <div aria-hidden="true" />
      <div aria-hidden="true" />
      <div aria-hidden="true" />
    </button>
  );
};

export default BurgerButton;
