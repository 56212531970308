import { h } from 'preact';
import { useState } from 'preact/hooks';
import classNames from 'classnames';

import BackButton from './backButton';
import BurgerButton from './burgerButton';
import Menu from './menu';

import style from './style';

const HeaderComponent = (props) => {
  const {
    children,
    right,
    transparent,
    floating,
    curved,
    light,
    shadow,
    inverted,
    left: LeftEl = BackButton,
  } = props;

  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => setMenuActive(!menuActive);

  const backdropTouchHandler = (evt) => {
    evt.preventDefault();
    toggleMenu();
  };

  const burgerFn = () => (
    <BurgerButton onClick={toggleMenu} isActive={menuActive} inverted={inverted} />
  );
  const RightEl = right === undefined ? burgerFn : right;

  return (
    <nav
      className={classNames(style.navbar, {
        [style.transparent]: transparent,
        [style.floating]: floating,
        [style.curved]: curved,
        [style.light]: light,
        [style.shadow]: shadow,
      })}
      role="navigation"
      aria-label="main navigation"
    >
      <div
        className={classNames({ [style.activeOverlay]: menuActive, [style.overlay]: !menuActive })}
        onTouchEnd={backdropTouchHandler}
      />

      <div
        className={classNames(style.navbarInner, {
          [style.active]: menuActive,
          [style.transparent]: transparent,
        })}
      >
        {LeftEl && !menuActive && <LeftEl />}

        {/* we need this div just to push burger button to right if there are no other elements */}
        {children || <div />}

        {RightEl && <RightEl inverted={light} />}
      </div>

      {RightEl === burgerFn && <Menu isActive={menuActive} closeFn={toggleMenu} />}
    </nav>
  );
};

export default HeaderComponent;
