import { h } from 'preact';
import { Link, getCurrentUrl } from 'preact-router';
import { connect } from 'redux-zero/react';
import classNames from 'classnames';
import faHome from '@fortawesome/fontawesome-pro/svgs/light/home.svg';
import faImages from '@fortawesome/fontawesome-pro/svgs/light/images.svg';
import faEnvelope from '@fortawesome/fontawesome-pro/svgs/light/envelope.svg';
import faGlobeStand from '@fortawesome/fontawesome-pro/svgs/light/globe-stand.svg';
import faClipboardList from '@fortawesome/fontawesome-pro/svgs/light/clipboard-list.svg';
import faLockAlt from '@fortawesome/fontawesome-pro/svgs/light/lock-alt.svg';
import faDownload from '@fortawesome/fontawesome-pro/svgs/light/download.svg';

import { openModal } from '../../contactUsModal/actions';
import { open } from '../../languageModal/actions';
import { sendLog } from '../../../helpers/apis/logger';
import { openInstallPrompt, canInstall } from '../../../helpers/installPrompt';

import Intl, { translate } from '../../common/intl';
import FaIcon from '../../common/faIcon';

import style from '../style';

const Menu = (props) => {
  const {
    isActive,
    languages,
    slug,
    userId,
    closeFn,
    openLanguageModal,
    openContactUsModal,
    srs,
    isUserBanned = false,
  } = props;

  const langClickHandler = () => {
    sendLog('info', {}, getCurrentUrl(), 'languageModal', 'triggered');
    openLanguageModal();
    closeFn();
  };

  const logLinkHandler = (link) => {
    sendLog('info', {}, getCurrentUrl(), link, 'triggered');
    closeFn();
  };

  const privacyLink = translate('I18N_TERMS_MODAL_PRIVACY_POLICY_LINK', 'https://brizicam.com/privacy/');
  const termsLink = translate('I18N_TERMS_MODAL_TOS_LINK', 'https://brizicam.com/terms/');

  const contactUsHandler = () => {
    sendLog('info', {}, window.location.pathname, 'contactUsModal', 'triggered');
    const SRS = `${srs.section || '_'}-${srs.row || '_'}-${srs.seat || '_'}`;
    openContactUsModal(`Question, event: ${slug}, ticket# ${userId}${isUserBanned ? '!' : ''} ${SRS}`);
    closeFn();
  };

  const installClickHandler = () => {
    openInstallPrompt();
    closeFn();
    sendLog('info', { type: 'pwa', event: 'install btn click' });
  };

  const LangBtn = () => languages && Object.keys(languages).length > 1
    && (
      /* eslint-disable-next-line */
      <div
        className={style.navbarItem}
        onClick={langClickHandler}
      >
        <span className={style.menuItemIcon}>
          <FaIcon icon={faGlobeStand} />
        </span>
        <span className={style.menuItemText}>
          <Intl textKey="I18N_MENU_LANGUAGE">Language</Intl>
        </span>
      </div>
    );

  const InstallBtn = () => canInstall() && (
    // eslint-disable-next-line
    <a href="#" className={style.navbarItem} onClick={installClickHandler}>
      <span className={style.menuItemIcon}>
        <FaIcon icon={faDownload} />
      </span>
      <span className={style.menuItemText}>
        <Intl textKey="I18N_MENU_INSTALL">
          Add to Home Screen
        </Intl>
      </span>
    </a>
  );

  return (
    <div className={classNames(style.menu, { [style.menuActive]: isActive })}>
      <div className={style.navbarStart}>
        <Link className={classNames(style.navbarItem, { [style.active]: getCurrentUrl() === '/' })} href="/">
          <span className={style.menuItemIcon}>
            <FaIcon icon={faHome} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_HOME">Home</Intl>
          </span>
        </Link>
        <Link className={classNames(style.navbarItem, { [style.active]: getCurrentUrl() === '/album' })} href="/album">
          <span className={style.menuItemIcon}>
            <FaIcon icon={faImages} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_ALBUM">Photos</Intl>
          </span>
        </Link>
        <LangBtn />
        {/* eslint-disable-next-line */}
        <div
          className={style.navbarItem}
          onClick={contactUsHandler}
        >
          <span className={style.menuItemIcon}>
            <FaIcon icon={faEnvelope} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_CONTACT_US">Contact Us</Intl>
          </span>
        </div>
        {/* eslint-disable-next-line */}
        <a
          href={termsLink}
          target="_blank"
          rel="noopener noreferrer"
          className={style.navbarItem}
          onClick={() => logLinkHandler(termsLink)}
        >
          <span className={style.menuItemIcon}>
            <FaIcon icon={faClipboardList} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_TERMS">
              Terms and Conditions
            </Intl>
          </span>
        </a>
        {/* eslint-disable-next-line */}
        <a
          href={privacyLink}
          target="_blank"
          rel="noopener noreferrer"
          className={style.navbarItem}
          onClick={() => logLinkHandler(privacyLink)}
        >
          <span className={style.menuItemIcon}>
            <FaIcon icon={faLockAlt} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_PRIVACY">
              Privacy Policy
            </Intl>
          </span>
        </a>
        <InstallBtn />
      </div>
    </div>
  );
};

export default connect(
  ({
    event: { languages, slug },
    userId = {},
    srs,
    isUserBanned,
  }) => ({
    languages,
    slug,
    userId,
    srs,
    isUserBanned,
  }),
  () => ({ openLanguageModal: open, openContactUsModal: openModal }),
)(Menu);
